/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
iframe,
p,
a,
img,
strong,
b,
u,
i,
ol,
ul,
li,
form,
canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
html {
  width: 100%;
}
body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

li {
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  overflow-x: hidden !important;
  /* font-weight: 500; */
}
option::-ms-expand {
  display: none;
}
option {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
/* --背景色字体颜色--*/
option:hover {
  color: #fff;
  background-color: #1e90ff;
}
.epub-container {
  overflow-x: hidden !important;
}
