.sidebar {
  width: 190px;
  height: 100%;
}

.logo {
  width: 125px;
  position: absolute;
  top: 15px;
  left: 70px;
  cursor: pointer;
}
.side-menu-container-parent {
  position: relative;
  top: 85px;
  width: 210px;
  height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.side-menu-container-parent:hover::-webkit-scrollbar-thumb,
.side-menu-container-parent:active::-webkit-scrollbar-thumb,
.side-menu-container-parent:focus::-webkit-scrollbar-thumb {
  display: block;
  width: 1px;
}
.side-menu-container-parent::-webkit-scrollbar-thumb {
  display: none;
}
.side-menu-container-parent::-webkit-scrollbar {
  width: 2px;
  height: 0.5rem;
}
.sidebar-list {
  margin-right: 5px;
  font-size: 22px;
}
.sidebar-list-icon {
  width: 45px;
  height: 50px;
  position: absolute;
  top: 20px !important;
  left: 10px;
  cursor: pointer;
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  transition: 0.2s;
}
.sidebar-shelf-icon {
  font-size: 19px;
}
.sidebar-list-icon:hover {
  border-radius: 50%;
}
.side-menu-container {
  /* overflow: hidden; */
  width: 100%;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  position: relative;
}
.side-shelf-container {
  /* overflow: hidden; */
  width: 100%;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  position: relative;
}
@media screen and (max-width: 950px) {
  .side-menu-container-parent {
    height: calc(100% - 155px);
  }
}
.side-menu-icon {
  /* float: left; */
  font-size: 22px;
  margin: 9px 12px 7px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.side-menu-item {
  margin-top: 2px;
  list-style-type: none;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: -20px;
  margin-bottom: 3px;
  position: relative;
  bottom: 1px;
  overflow: hidden;
  transition: 0.1s;
}
.side-menu-selector {
  width: 100%;
  height: 39px;
  line-height: 39px;
  z-index: 40;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.shelf-list-container-parent {
  margin-top: 20px;
  height: 140px;
  width: 80%;
}
.shelf-list-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  list-style-type: none;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.side-shelf-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  display: inline-block;
}
.side-shelf-title-container {
  width: 70%;
  margin-left: 10%;
  border-bottom: 1px solid #ccc;
}
.side-shelf-title-icon {
  margin-top: 14px;
  float: right;
  font-weight: 500;
  font-size: 12px;
  transform-origin: center center;
  transition: transform 0.1s ease;
  cursor: pointer;
}
.side-menu-selector-container {
  width: 100%;
  height: 39px;
  border-radius: 25px;
  line-height: 39px;
  position: absolute;
  z-index: -1;
  animation: popup 0.1s ease-in-out 0s 1;
}
.icon-digest {
  font-size: 22px;
}
.side-menu-hover-container {
  width: 100%;
  height: 39px;
  border-radius: 25px;
  line-height: 39px;
  position: absolute;
  z-index: -1;
  animation: slidein 0.1s ease-in-out 0s 1;
}
@keyframes slidein {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.side-menu-about {
  position: absolute;
  bottom: 0px;
}
.dropdown-icon-container {
  display: inline-block;
  margin-left: 13px;
}

.icon-idea {
  font-size: 20px;
}
