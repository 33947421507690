.header-search-box {
  width: calc(100% - 20px);
  height: 39px;
  border-radius: 22px;
  border-style: none;
  outline: none;
  font-size: 15px;
  padding: 0px;
  padding-left: 20px;
}
.header-search-box::placeholder {
  font-size: 15px;
  line-height: 39px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 40px);
}
.header-search-icon {
  font-size: 22px;
  display: inline-block;
  opacity: 0.6;
  cursor: pointer;
}
.header-search-text {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 15px;
  display: inline-block;
  width: 40px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-search-text:hover {
  border-radius: 50%;
}
.search-text {
  font-size: 18px;
  position: relative;
  right: 180px;
  bottom: 4px;
}
