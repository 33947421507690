.back-main-container {
  position: fixed;
  bottom: 42px;
  left: -21px;
  width: 140px;
  height: 40px;
  border-radius: 20px;
  background-color: #8b8b8b;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 5px;
}
.back-main-container:hover {
  animation: stretch 0.3s forwards;
}

@keyframes stretch {
  0% {
    width: 30px;
  }
  100% {
    width: 140px;
  }
}
@keyframes shrink {
  0% {
    width: 140px;
  }
  100% {
    width: 30px;
  }
}

.back-home-home,
.back-home-day,
.back-home-night,
.back-home-eye,
.back-home-dropdown {
  color: #fcfcfc;
}
.back-home-home:hover,
.back-home-day:hover,
.back-home-night:hover,
.back-home-eye:hover,
.back-home-dropdown:hover {
  opacity: 0.6;
}
.back-home-dropdown {
  font-size: 15px;
  transform: rotate(-90deg);
  position: relative;
  left: 5px;
}
.back-home-eye {
  font-size: 22px;
}
.back-home-day {
  font-size: 22px;
}
.back-home-night {
  font-size: 18px;
}
