.navigation-header {
  position: fixed;
  width: 299px;
  height: 173px;
  opacity: 1;
  z-index: 10;
  overflow-x: hidden;
}
.book-cover {
  float: left;
  width: 91px;
  height: 118px;
  position: relative;
  left: 12px;
  top: 14px;
}
/* .book-title-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
} */
.book-title {
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  opacity: 1;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  position: absolute;
  left: 30px;
  top: 20px;
  position: relative;
}
.book-arthur {
  height: 30px;
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  position: absolute;
  left: 30px;
  top: 10px;
  position: relative;
}

.reading-duration {
  position: absolute;
  left: 120px;
  top: 60px;
  font-size: 14px;
  line-height: 22px;
}

.book-content-title {
  line-height: 27px;
  opacity: 1;
  cursor: pointer;
  width: 23%;
  overflow-x: hidden;
  text-align: center;
  margin: 1% 1%;
}
.book-bookmark-title {
  line-height: 27px;
  opacity: 1;
  cursor: pointer;
  max-width: 40%;
  overflow-x: hidden;
  text-align: center;
  margin: 1% 1%;
}
.navigation-body-parent {
  position: absolute;
  top: 173px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 173px);
}
.navigation-body {
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  z-index: 0;
  overflow-x: hidden;
}
.navigation-body:hover::-webkit-scrollbar-thumb,
.navigation-body:active::-webkit-scrollbar-thumb,
.navigation-body:focus::-webkit-scrollbar-thumb {
  display: block;
  width: 15px;
  border-radius: 0;
  opacity: 0.1;
  background-color: rgba(75, 75, 75, 0.2);
}
.navigation-body::-webkit-scrollbar-thumb {
  display: none;
}
.navigation-body::-webkit-scrollbar {
  width: 15px;
}
.navigation-panel-empty-bookmark {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 60px;
}
.navigation-navigation {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.navigation-panel {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 299px;
  height: 100%;
  opacity: 1;
  z-index: 10;
  animation: fade-left 0.2s ease-in-out 0s 1;
}
.navigation-search-box {
  position: absolute;
  left: 115px;
  top: 90px;
}
.navigation-search-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 44px);
  height: 38px;
  font-size: 15px;
  margin: 6px 22px;
  font-weight: 500;
}
.nav-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  transition: 0.1s;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-close-icon:hover {
  border-radius: 50%;
}
.nav-search-list {
  position: absolute;
  padding: 10px;
  top: 110px;
  font-size: 15px;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  width: calc(100% - 20px);
}
.nav-search-list-item {
  padding: 8px;
  cursor: pointer;
  word-break: break-all;
  word-wrap: break-word;
}
.content-search-text {
  color: red !important;
  font-weight: 600 !important;
}
.nav-search-page {
  position: absolute;
  left: 40px;
  bottom: 7px;
  display: flex;
  justify-content: space-around;
  width: calc(100% - 80px);
  margin-top: 10px;
}
.nav-search-page-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.nav-lock-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  opacity: 0.3;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
}
