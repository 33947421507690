.manager {
  width: 100%;
  height: 100%;
}
.token-dialog-token-text {
  width: 100%;
  height: 29px;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
}
.auth-page-close-icon {
  font-size: 60px !important;
  float: none !important;
}
