.loading-dialog {
  width: 454px;
  height: 278px;
  position: absolute;
  left: calc(50% - 225px);
  top: calc(50% - 150px);
  overflow: hidden;
  opacity: 1;
  z-index: 20;
  border-radius: 5px;
  animation: popup 0.1s ease-in-out 0s 1;
}
.loading-dialog-title {
  width: 120px;
  height: 20px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
}
.loader {
  width: 200px;
  height: 140px;
  background: #979794;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  perspective: 1000px;
  transform: scale(0.8);
}
.loading-animation {
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader:before {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border-radius: 8px;
  background: #f5f5f5 no-repeat;
  background-size: 60px 10px;
  background-image: linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0);

  background-position: 15px 30px, 15px 60px, 15px 90px, 105px 30px, 105px 60px,
    105px 90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.loader:after {
  content: "";
  position: absolute;
  width: calc(50% - 10px);
  right: 10px;
  top: 10px;
  bottom: 10px;
  border-radius: 8px;
  background: #fff no-repeat;
  background-size: 60px 10px;
  background-image: linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0),
    linear-gradient(#ddd 100px, transparent 0);
  background-position: 50% 30px, 50% 60px, 50% 90px;
  transform: rotateY(0deg);
  transform-origin: left center;
  animation: paging 1s linear infinite;
}

@keyframes paging {
  to {
    transform: rotateY(-180deg);
  }
}
