.edit-dialog-container {
  width: 309px;
  height: 189px;
  opacity: 1;
  position: absolute;
  left: calc(50% - 155px);
  top: calc(50% - 95px);
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}
.edit-dialog-title {
  width: 120px;
  height: 20px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 22px;
  font-weight: 500;
}
.edit-dialog-book-name-container {
  width: 100%;
  font-size: 16px;
  line-height: 14px;
  opacity: 1;
  position: absolute;
  top: 55px;
}
.edit-dialog-book-name-text {
  width: 50px;
  float: left;
  margin-left: 25px;
  margin-top: 7px;
}
.edit-dialog-book-name-box {
  float: left;
  width: 210px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  padding-left: 5px;
  font-size: 15px;
  border-radius: 5px;
}
.edit-dialog-book-author-container {
  width: 100%;
  font-size: 15px;
  line-height: 14px;
  opacity: 1;
  position: absolute;
  top: 95px;
}
.edit-dialog-book-author-text {
  width: 50px;
  float: left;
  margin-left: 25px;
  margin-top: 7px;
}
.edit-dialog-book-author-box {
  float: left;
  width: 210px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  padding-left: 5px;
  font-size: 15px;
  border-radius: 5px;
}
.edit-dialog-cancel {
  width: 60px;
  height: 26px;
  opacity: 1;
  position: absolute;
  left: 147px;
  top: 144px;

  font-size: 13px;
  border-radius: 5px;
  line-height: 26px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}
.edit-dialog-comfirm {
  width: 64px;
  height: 30px;
  opacity: 1;
  position: absolute;
  left: 220px;
  top: 144px;
  font-size: 13px;
  border-radius: 5px;
  line-height: 30px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}
