.setting-dialog-container {
  width: 346px;
  height: 480px;
  position: absolute;
  left: calc(50% - 173px);
  top: calc(50% - 240px);
  overflow: hidden;
  opacity: 1;
  z-index: 20;
  border-radius: 5px;
  animation: popup 0.1s ease-in-out 0s 1;
}
.setting-dialog-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  opacity: 1;
  margin: 15px 0px 10px;
  text-align: center;
  width: 346px;
}
.setting-dialog-new-title {
  font-size: 15px;
  line-height: 20px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 5px;
  width: 280px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.setting-dialog-list {
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  margin: 7px;
}
.setting-dialog-new-container {
  margin: 20px 30px;
}
.setting-dialog-info {
  height: 370px;
  overflow-y: scroll;
  width: 346px;
}
.setting-dialog-illustration {
  width: 200px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 20;
}
.setting-dialog-url {
  width: 200px;
  font-size: 13px;
  position: absolute;
  bottom: 6px;
  left: 10px;
  user-select: text;
  z-index: 30;
  line-height: 16px;
}
.setting-dialog-subtitle {
  font-size: 15px;
  margin: 7px 10px;
  text-align: center;
  color: #0078d4;
  float: left;
}

.setting-language {
  width: 30px;
  height: 30px;
  font-size: 30px;
  cursor: pointer;
  float: right;
}

.setting-close-container {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 0.1s;
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.setting-close-container:hover {
  border-radius: 50%;
}
.setting-close {
  margin-right: 10px;
  margin-top: 10px;
}
.setting-subtitle {
  font-size: 15px;
  margin: 5px;
  margin-bottom: 0px;
  text-align: center;
}
.setting-plugin-icon {
  margin: 5px;
  font-size: 18px;
  display: inline-block;
  width: 20px;
}
.setting-plugin-name {
  position: relative;
  bottom: 3px;
}
.lang-setting-dropdown {
  width: 100px;
  height: 30px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
.lang-setting-option {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}
.setting-dialog-location-title {
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
  width: 75%;
  margin-left: 10.5%;
  text-overflow: ellipsis;
  word-break: break-all;
  margin-top: 6px;
  margin-bottom: 10px;
  user-select: text;
}
.change-location-button {
  display: inline-block;
  width: 60px;
  height: 22px;
  opacity: 1;
  border-radius: 11px;
  position: relative;
  top: 0px;
  left: 2px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.theme-setting-item {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
}
.theme-setting-container {
  width: 150px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setting-option-subtitle {
  font-size: 12px;
  opacity: 0.8;
  margin-left: 30px;
  margin-right: 25px;
  line-height: 15px;
}
