.new-version {
  width: 346px;
  height: 480px;
  position: absolute;
  left: calc(50% - 173px);
  top: calc(50% - 240px);
  overflow: hidden;
  opacity: 1;
  z-index: 20;
  border-radius: 5px;
  animation: popup 0.1s ease-in-out 0s 1;
}
.new-version-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  opacity: 1;
  margin: 20px 0px 10px;
  text-align: center;
  width: 346px;
}
.new-version-open {
  margin-left: 115px;
  margin-top: -5px;
  width: 98px;
  padding: 0px 10px;
  height: 32px;
  opacity: 1;
  border-radius: 25px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  z-index: 50;
  transition: 0.1s;
  font-size: 13px;
}
.new-version-copy {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  margin-left: 148px;
  display: inline-block;
}
.update-dialog-new-title,
.update-dialog-fix-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 20px;
}
.update-dialog-list {
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  margin: 12px;
}
.update-dialog-new-container,
.update-dialog-fix-container {
  margin: 20px 10px;
}
.update-dialog-info {
  margin-top: 10px;
  height: 330px;
  overflow-y: scroll;
  width: 346px;
}
.update-dialog-illustration {
  width: 200px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 20;
}
.update-dialog-container-button {
  width: 75px;
  height: 32px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
  margin: 12px 130px;
  position: absolute;
  cursor: pointer;
  z-index: 25;
}
.update-dialog-url {
  width: 200px;
  font-size: 13px;
  position: absolute;
  bottom: 6px;
  left: 10px;
  user-select: text;
  z-index: 30;
  line-height: 16px;
}
.update-dialog-subtitle {
  font-size: 13px;
  margin: 5px;
  text-align: center;
}
.update-info-text {
  text-align: center;
  font-size: 15px;
  opacity: 0.6;
  margin-top: 15px;
}
