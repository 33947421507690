.delete-dialog-container {
  width: 319px;
  height: 199px;
  opacity: 1;
  position: fixed;
  left: calc(50% - 160px);
  top: calc(50% - 100px);
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}
.delete-dialog-title {
  width: 120px;
  height: 25px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 22px;
  font-weight: 500;
}

.delete-dialog-book-title {
  position: absolute;
  top: 60px;
  width: calc(100% - 20px);
  line-height: 25px;
  font-size: 18px;
  opacity: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
  word-break: keep-all;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}
.delete-dialog-other-option {
  font-size: 13px;
  text-align: center;
  line-height: 18px;
  opacity: 1;
  position: absolute;
  top: 106px;
  padding-left: 5px;
  padding-right: 5px;
  width: calc(100% - 10px);
  /* margin-left: 25px; */
}

.delete-dialog-uncheck-icon {
  width: 12px;
  height: 12px;
  border-radius: 5px;
  opacity: 1;
  position: absolute;
  top: 106px;
  right: 25px;
  cursor: pointer;
}

.icon-close {
  float: right;
  font-size: 18px;
  cursor: pointer;
}
